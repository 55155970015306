import UpdateAmounts from "./update_amounts";

export default function CartEvents() {

  document.addEventListener('click', (event) => {

    let role = event.target.getAttribute('data-role');

    if (role === 'cart-course-trigger') {
      UpdateAmounts();
    }
  });
}
