export default function UpdateCartView(totals) {

  // assign newly calculated amounts
  document.querySelector('[data-role="cart-volume-discount"]').innerHTML = totals.volume_discount;
  document.querySelector('[data-role="cart-gross-amount"]').innerHTML = totals.gross_amount;
  document.querySelector('[data-role="cart-tax-amount"]').innerHTML = totals.tax_amount;
  document.querySelector('[data-role="cart-total-amount"]').innerHTML = totals.total_amount;

  // and reenable
  document.querySelectorAll('[data-role="cart-course"]').forEach(el => {

    let id = parseInt(el.getAttribute('data-id'));

    el.checked = totals.courses.indexOf(id) !== -1;
    el.disabled = false;
  });
}
