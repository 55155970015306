export default function GetFormData() {

  let courses = [];

  document
    .querySelectorAll('input[name="cart[courses][]"]:checked')
    .forEach(checkbox => courses.push(checkbox.value));

  return JSON.stringify({

    _method: 'POST',
    authenticity_token: document.querySelector('input[name="authenticity_token"]').value,
    cart: {

      id: document.querySelector('input[name="cart[id]"]').value,
      courses: courses
    }
  })
}
