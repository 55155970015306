import 'whatwg-fetch';
import GetFormData from './get_form_data';
import UpdateCartView from './update_cart_view';

export default function UpdateAmounts() {

  // queue updating price to next tick using #setTimeout
  // after browser registers checkbox as checked
  window.setTimeout(() => {

    // first disable checkboxes to prevent multiple submits
    document.querySelectorAll('input[type="checkbox"]').forEach(checkbox => checkbox.disabled = true);

    // recalculate cart
    let url  = document.querySelector('[data-url]').getAttribute('data-url');
    window
      .fetch(url, {

        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: GetFormData(),
      })
      .then(response => response.json())
      .then(totals => {
        UpdateCartView(totals);
      });

  }, 0);
}
